
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import AdvanceSearch from './AdvanceSearch.vue'
  import { FilterField } from '@/entities/public/Resource/interfaces'
  import { mapGetters } from 'vuex'
  import { Resource } from '@/entities/public'

@Component({
  components: {
    AdvanceSearch,
  },
  computed: {
    ...mapGetters('resources', ['active']),
    ...mapGetters('resources/datatables', ['datatableLoading']),
  },
})
  export default class MainSearch extends Vue {
  @Prop({ type: String, default: '' }) errorMessage!: string

  private active!: Resource

  menu: boolean = false
  switchVal = false
  selectedFilters: any[] = []
  datatableLoading!: boolean;

  get filterFields (): FilterField[] {
    const { active } = this

    return active?.metadata?.filterFields || []
  }

  get withOutFilter () {
    return !this.filterFields.length
  }

  removeFilter (index: number) {
    this.selectedFilters.splice(index, 1)
    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
  }

  onCancel () {
    this.menu = false
  }

  onSearch (filterFields: FilterField[]) {
    const activeFilters = filterFields.filter(
      field => field.value !== undefined && field.value !== null && field.value !== ''
    )

    this.selectedFilters = activeFilters
      .map(field => {
        const processedFilter = this.active.metadata.replaceSearchInFilter(
          field.filter,
          field.value
        )
        if (processedFilter !== null) {
          return {
            label: field.label,
            value: field.value,
            filter: processedFilter,
          }
        }
        return null
      })
      .filter(filter => filter !== null)

    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
    this.menu = false
  }

  onChipInput (value: any) {
    this.selectedFilters = value.map((chip: any) => chip.value)
    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
  }
  }
